import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import {
  CommonResponseModel,
  CommonResponseSecondaryModel,
  CustomerDetailModel,
  LoaderDetailsResponseModel,
  LoginPayloadModel,
  RegisterDataResponseModel,
  RegisterPayloadModel,
  SendLoginOTPPayloadModel,
  SendOTPResponseDataModel,
  UserMeResponseDataModel,
  VerifyAccountOTPPayloadModel,
  customerSubscriptionDetailsModel,
  errorModel,
  infoModel,
  languageListObj,
  loaderFilterdetails,
  loaderProps,
  promptLogFieldObjModel,
  stripeCurrencyTypeObjModel,
  successModel,
} from "../models/axiosModel";
import agent from "../services/api";

const UserContext = React.createContext<UserContextPayload | undefined>(
  undefined
);

export interface UserContextPayload {
  flagLoading: loaderProps;
  setFlagLoading: React.Dispatch<React.SetStateAction<loaderProps>>;
  userDetail: UserMeResponseDataModel;
  setUserDetail: React.Dispatch<React.SetStateAction<UserMeResponseDataModel>>;
  UserMe: () => Promise<CommonResponseModel<RegisterDataResponseModel>>;
  Register: (payload: RegisterPayloadModel) => Promise<CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel>>;
  SendAccountOTP: () => Promise<CommonResponseSecondaryModel<SendOTPResponseDataModel>>;
  OneTapLoginAuthCallback: (payload: any) => Promise<any>;
  VerifyAccount: (payload: VerifyAccountOTPPayloadModel) => Promise<CommonResponseSecondaryModel<{}>>;
  SendLoginOTP: (payload: SendLoginOTPPayloadModel) => Promise<CommonResponseSecondaryModel<SendOTPResponseDataModel>>;
  Login: (payload: LoginPayloadModel) => Promise<CommonResponseModel<RegisterDataResponseModel>>;
  error: errorModel;
  setError: React.Dispatch<React.SetStateAction<errorModel>>;
  success: successModel;
  setSuccess: React.Dispatch<React.SetStateAction<successModel>>;
  customerDetail: CustomerDetailModel;
  setCustomerDetail: React.Dispatch<React.SetStateAction<CustomerDetailModel>>;
  GetCustomerDetails: () => void;
  UpdateCustomerDetails: (payload: CustomerDetailModel) => Promise<boolean>;
  creditUsed: any;
  setCreditUsed: any;
  customerSubscriptionDetails: null | customerSubscriptionDetailsModel,
  setCustomerSubscriptionDetails: React.Dispatch<React.SetStateAction<null | customerSubscriptionDetailsModel>>;
  GetCustomerSubscriptionDetails: () => void;
  flagOnboardingSkip: boolean | null,
  setFlagOnboardingSkip: React.Dispatch<React.SetStateAction<boolean | null>>;
  showInfo: infoModel,
  setShowInfo: React.Dispatch<React.SetStateAction<infoModel>>
  languageList: languageListObj[];
  setLanguageList: React.Dispatch<React.SetStateAction<languageListObj[]>>
  getLanguagesList: () => void;
  flagChromeDesktop: boolean | null;
  setFlagChromeDesktop: React.Dispatch<React.SetStateAction<null | boolean>>
  setStripeCurrencyTypeGenericList: React.Dispatch<React.SetStateAction<stripeCurrencyTypeObjModel[]>>
  stripeCurrencyTypeGenericList: stripeCurrencyTypeObjModel[]
  GetStripeCurrencyTypeGenericList: () => void;
  promptLogFieldGenericList: promptLogFieldObjModel[];
  setPromptLogFieldGenericList: React.Dispatch<React.SetStateAction<promptLogFieldObjModel[]>>
  GetPromptLogFieldGenericList: () => void;
  loadingURL: any;
  setLoadingURL: any
  flagShowCreditsExhausted: boolean;
  setFlagShowCreditsExhausted: React.Dispatch<React.SetStateAction<boolean>>;
  showLoginPopup: boolean;
  setShowLoginPopup: React.Dispatch<React.SetStateAction<boolean>>;
  getLoaderDetails: () => Promise<CommonResponseModel<LoaderDetailsResponseModel | null>>;
  setLoaderDetails: React.Dispatch<React.SetStateAction<LoaderDetailsResponseModel | null>>;
  loaderDetails: LoaderDetailsResponseModel | null;

  loaderFilterDetails: any;
  setLoaderFilterDetails: any;


  loaderFilterDataList: loaderFilterdetails,
  setLoaderFilterDataList: React.Dispatch<React.SetStateAction<loaderFilterdetails>>,

  setLoaderIndex: any,
  loaderIndex: any

  // loadingURL: any;
  // setLoadingURL: any
  // SendAccountOTP:() => CommonResponseModel<SendOTPResponseDataModel>
  // VerifyAccount:(payload:VerifyAccountOTPPayloadModel) => CommonResponseModel<SendOTPResponseDataModel>
  // SendLoginOTP:(payload:SendLoginOTPPayloadModel) => CommonResponseModel<SendOTPResponseDataModel>
  // Login:(payload:LoginPayloadModel) => CommonResponseModel<RegisterDataResponseModel>
}

const UserProvider = ({ children }: ChildrenProps) => {
  const [flagLoading, setFlagLoading] = useState<loaderProps>({
    flagRoundLoader: false,
    flagContentGenerating: false
  });
  const [loadingURL, setLoadingURL] = useState("default");

  const emptyUserDetail = {
    flagUser: null,
    flag_user_anonymous: null,
    flag_email_verified: null,
    user_id: 0,
    user_type: "",
    user_first_name: "",
    user_last_name: "",
    user_email: "",
    user_assignment_id: 0,
    user_phone_number: "",
    user_status: {
      user_status_id: 0,
      user_status_name: ""
    },
    user_popup_actions: [],
    plan_detail: null,
    user_role: {
      user_role_id: 0,
      user_role_name: ""
    },
  };
  const emptyErrorObj = {
    flagShowError: false,
    message: "",
  };
  const emptySuccessObj = {
    flagShowSuccess: false,
    message: "",
  };
  const emptyInfoObj = {
    flagShowSuccess: false,
    message: "",
  };
  const emptyCustomerObj = {
    gc_stripe_customer_id: 0,
    stripe_customer_id: "",
    user_id: 0,
    company_id: 0,
    stripe_customer_first_name: "",
    stripe_customer_last_name: "",
    stripe_customer_email: "",
    stripe_customer_add1: "",
    stripe_customer_add2: "",
    stripe_customer_city: "",
    stripe_customer_state: "",
    stripe_customer_country: "",
    stripe_customer_postal_code: "",
    stripe_customer_phone: "",
    stripe_customer_flag_is_indian: true,
    stripe_customer_tax_id: "",
    created_at_timestamp: "",
    updated_at_timestamp: "",
  }

  const [userDetail, setUserDetail] = useState<UserMeResponseDataModel>(emptyUserDetail);

  const [loaderDetails, setLoaderDetails] = useState<LoaderDetailsResponseModel | null>(null);
  const [loaderFilterDetails, setLoaderFilterDetails] = useState<loaderFilterdetails>({
    index: 0,
    loaderDetails: []
  });

  const [loaderIndex, setLoaderIndex] = useState<any>(0);
  const [loaderFilterDataList, setLoaderFilterDataList] = useState<any>(null)
  const [error, setError] = useState<errorModel>(emptyErrorObj);
  const [success, setSuccess] = useState<successModel>(emptySuccessObj);
  const [showInfo, setShowInfo] = useState<infoModel>(emptyInfoObj)
  const [customerDetail, setCustomerDetail] = useState<CustomerDetailModel>(emptyCustomerObj)
  const [creditUsed, setCreditUsed] = useState<any>()
  const [customerSubscriptionDetails, setCustomerSubscriptionDetails] = useState<null | customerSubscriptionDetailsModel>(null)
  const [flagOnboardingSkip, setFlagOnboardingSkip] = useState<boolean | null>(null)
  const [languageList, setLanguageList] = useState<languageListObj[] | []>([])
  const [flagChromeDesktop, setFlagChromeDesktop] = useState<boolean | null>(null)
  const [stripeCurrencyTypeGenericList, setStripeCurrencyTypeGenericList] = useState<stripeCurrencyTypeObjModel[]>([])
  const [promptLogFieldGenericList, setPromptLogFieldGenericList] = useState<promptLogFieldObjModel[]>([])
  const [flagShowCreditsExhausted, setFlagShowCreditsExhausted] = useState(false)
  const [showLoginPopup, setShowLoginPopup] = useState(false)
  const UserMe = async () => {
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });
    setLoadingURL(agent.UserMeAPI.getURL)
    const response = await agent.UserMeAPI.get();
    if (response.status) {
      setUserDetail(prevUserDetail => {
        const updatedUserDetail = {
          ...prevUserDetail,
          flagUser: response.status,
          ...response.data[0],
        };
        if (prevUserDetail.flagUserFromParaphrase) {
          updatedUserDetail.flagUserFromParaphrase = prevUserDetail.flagUserFromParaphrase;
        }
        return updatedUserDetail;
      });
      let tempUserDetail = {
        flaguserAnonymous: response.data[0].flag_user_anonymous,
        userfirstName: response.data[0].user_first_name,
        userLastName: response.data[0].user_last_name,
        userFullName: ` ${response.data[0].user_first_name} ${response.data[0].user_last_name}`,
        userStatus: response.status
      }
      if (response.data[0].plan_detail.credit_usage_data !== null) {
        const temp = {
          remaining_credit_usage: response.data[0].plan_detail.credit_usage_data.remaining_credit_usage,
          total_credit_usage: response.data[0].plan_detail.credit_usage_data.total_credit_usage
        }
        setCreditUsed(temp);
      }
      SendPostMessageToExtention(tempUserDetail)

    } else {
      setUserDetail({ ...emptyUserDetail, flagUser: false });
      SendPostMessageToExtention(null)
    }
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    return response;
  };
  const getLanguagesList = async () => {
    const response = await agent.getLanguageList.get()
    if (response.status) {
      setLanguageList(response.data)
    } else {
      setLanguageList([])
    }
  }
  const Register = async (payload: RegisterPayloadModel): Promise<CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel>> => {
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });
    const response = await agent.RegisterAPI.post(payload);
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    return response;
  }
  const SendAccountOTP = async () => {
    const response = await agent.SendAccountOTPAPI.post();
    return response;
  };
  const OneTapLoginAuthCallback = async (payload: any) => {
    const response = await agent.oneTapLoginAuthCallback.post(payload);
    return response;
  };
  const VerifyAccount = async (payload: VerifyAccountOTPPayloadModel) => {
    const response = await agent.VerifyAccountOTPAPI.put(payload);
    return response;
  };
  const SendLoginOTP = async (payload: SendLoginOTPPayloadModel) => {
    const response = await agent.SendLoginOTPAPI.post(payload);
    return response;
  };
  const Login = async (payload: LoginPayloadModel) => {
    const response = await agent.VerifyLoginOTPAPI.put(payload);
    return response;
  };
  const GetCustomerDetails = async () => {
    const response = await agent.getCustomerDetail.get();
    if (response.status) {
      setCustomerDetail(response.data[0])
    } else {
      setCustomerDetail(emptyCustomerObj)
    }
  };
  const UpdateCustomerDetails = async (payload: CustomerDetailModel) => {
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });
    const response = await agent.updateCustomerDetail.put(payload);
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    if (response.status) {

      // setCustomerDetail(response.data[0])
      setCustomerDetail(payload)
      setSuccess({ ...success, flagShowSuccess: true, message: "Customer Details Updated Successfully!" })
      return true
    } else {
      setError({ ...error, flagShowError: true, message: response.error.message })
      return false
    }
  }
  const GetPricingDetail = async () => {
    const response = await agent.getPricingDetail.get();
    return response;
  }
  const GetCustomerSubscriptionDetails = async () => {
    const response = await agent.getStripeCustomerSubscriptionDetail.get();
    if (response.status) {
      setCustomerSubscriptionDetails(response.data[0])
    } else {
      // setError
    }
    // return response;
  }
  const SendPostMessageToExtention = async (data: any) => {
    try {
      // window.postMessage({ type: "SET_WRITEWIZ_LOGIN_DETAILS", data: data }, "*");
      // The ID of the extension we want to talk to.
      const editorExtensionId = process.env.REACT_APP_WRITEWIZ_EXTENSION_ITEM_ID;
      // Make a simple request:
      // let abcd = {
      //   "editorExtensionId": editorExtensionId, asjhsa: {
      //     "type": "SET_WRITEWIZ_LOGIN_DETAILS",
      //     "data": data
      //   }
      // }
      chrome.runtime.sendMessage(editorExtensionId, {
        "type": "SET_WRITEWIZ_LOGIN_DETAILS",
        "data": data
      },
        function (response: any) {
          if (!response.success)
            console.log(response)
        });
    } catch (error) {
      // console.log(error);
    }
  }
  const GetStripeCurrencyTypeGenericList = async () => {
    const response = await agent.getStripeCurrencyTypeGenericList.get();
    if (response.status) {
      setStripeCurrencyTypeGenericList(response.data)
    } else {
    }
  }
  const GetPromptLogFieldGenericList = async () => {
    const response = await agent.getPromptLogFieldsGenericList.get();
    if (response.status) {
      setPromptLogFieldGenericList(response.data)
    } else {
    }
  }

  const getLoaderDetails = async () => {
    const response = await agent.LoaderDetailsAPI.get();
    return response
  }

  const payload = {
    flagLoading,
    setFlagLoading,
    userDetail,
    setUserDetail,
    UserMe,
    Register,
    SendAccountOTP,
    VerifyAccount,
    SendLoginOTP,
    Login,
    error,
    setError,
    customerDetail,
    setCustomerDetail,
    GetCustomerDetails,
    GetPricingDetail,
    UpdateCustomerDetails,
    creditUsed,
    setCreditUsed,
    OneTapLoginAuthCallback,
    customerSubscriptionDetails,
    setCustomerSubscriptionDetails,
    GetCustomerSubscriptionDetails,
    success,
    setSuccess,
    flagOnboardingSkip,
    setFlagOnboardingSkip,
    showInfo,
    setShowInfo,
    languageList,
    setLanguageList,
    getLanguagesList,
    flagChromeDesktop,
    setFlagChromeDesktop,
    GetStripeCurrencyTypeGenericList,
    stripeCurrencyTypeGenericList,
    setStripeCurrencyTypeGenericList,
    GetPromptLogFieldGenericList,
    promptLogFieldGenericList,
    setPromptLogFieldGenericList,
    loadingURL,
    setLoadingURL,
    flagShowCreditsExhausted,
    setFlagShowCreditsExhausted,
    showLoginPopup,
    setShowLoginPopup,
    getLoaderDetails,
    loaderDetails,
    setLoaderDetails,

    loaderFilterDataList,
    setLoaderFilterDataList,

    loaderFilterDetails,
    setLoaderFilterDetails,

    setLoaderIndex,
    loaderIndex
    // loadingURL,
    // setLoadingURL
  };

  return (
    <UserContext.Provider value={payload}>{children}</UserContext.Provider>
  );
};

export { UserContext, UserProvider };
